export const JOB_OFFERS = [
  {
    type: `Głuchołazy woj. Opolskie`,
    title: `Kierownik Działu Utrzymania Ruchu.`,
    description: [
      `Nasz Klient to dynamicznie rozwijający się i działający od ponad 25 lat holding firm z ugruntowaną i stabilną pozycją rynkową, prowadzący operacje w różnych branżach na rynku krajowym i na wielu rynkach zagranicznych.`,
    ],
    target: [
      `W związku z wdrażaniem planu rozwoju i poprawy jakości działalności produkcyjnej, poszukujemy doświadczonej, innowacyjnej i rozumiejącej procesy produkcyjne osoby, która przejmie odpowiedzialne stanowisko Kierownika Działu Utrzymania Ruchu w ramach wszystkich firm należących do grupy kapitałowej.`,
      `Jeśli mechanika maszyn jest tym, co dodaje Ci energii do działania, jesteś twórczy, lubisz pracę z ludźmi, a planowanie i organizowanie to Twoje atuty, wyślij swoją aplikację.`,
      `Twój nowy pracodawca oferuje Ci atrakcyjne wynagrodzenie, autonomię i samodzielność w działaniu abyś mógł osiągać swoje cele i czuć się dobrze w nowym miejscu pracy.`,
      `Osoba zatrudniona na tym stanowisku będzie podlegać bezpośrednio pod Prezesa firmy oraz raportować efekty swoich działań do zarządu przedsiębiorstwa.`,
      `Jeśli to brzmi dla Ciebie interesująco wyślij swoje CV na adres rekrutacje@daimly.pl,
      W tytule maila wpisz numer oferty: 19/04/2022`,
    ],
    receivers: [
      `Proponowanie i wdrażanie innowacyjnych rozwiązań, mających na celu poprawę jakości procesu produkcji`,
      `Organizowanie i sprawowanie nadzoru nad pracą Działu Utrzymania Ruchu, nadzorowanie realizacji zadań podległych pracowników, prowadzenie ewidencji realizowanych zadań`,
      `Doskonalenie wewnętrznej organizacji pracy Działu oraz opracowywanie metod i formy pracy podległych pracowników`,
      `Organizacja i nadzór nad magazynami części zamiennych i narzędzi`,
      `Opracowywanie, nadzorowanie i realizacja harmonogramów cyklów remontowych i konserwacji maszyn, urządzeń, linii produkcyjnych oraz obiektów budowlanych (we współpracy z wydziałem budowlanym)`,
      `Wdrażanie i egzekwowanie procedur zapewniających ciągłość pracy maszyn i urządzeń produkcyjnych`,
      `Bezpośrednie sprawowanie nadzoru nad infrastrukturą zakładu, maszyn oraz instalacji produkcyjnych, realizowanie planów przeglądów okresowych maszyn i urządzeń`,
      `Analiza przyczyn awarii i usterek oraz przygotowanie i wdrażanie działań prewencyjnych i naprawczych`,
      `Zapewnianie doradztwa technicznego przy podejmowaniu decyzji zakupowych oraz związanych nowymi inwestycjami`,
      `Nadzór nad dokumentacją techniczną maszyn, urządzeń i instalacji oraz zapewnienie ich aktualności i kompletności`,
      `Współpraca z innymi działami/wydziałami organizacji, podwykonawcami i serwisami zewnętrznymi`,
      `Dążenie do automatyzacji procesów produkcyjnych`,
      `Raportowanie i prowadzenie statystyk na potrzeby Zarządu`,
    ],
    structure: {
      description: `Aby skutecznie i efektywnie wykonywać swoje obowiązki, oraz osiągać cele jakie organizacja postawi wybranemu Kandydatowi/ Kandydatce  idealna osoba powinna spełniać następujące kryteria i powinna posiadać:`,
      modules: [
        `Wykształcenie wyższe, o kierunku technicznym (preferowane kierunki: mechanika, automatyka, budowa maszyn lub pokrewne)`,
        `Znajomość parku maszynowego m.in obrabiarek CMC, robotów spawalniczych, ploterów frezujących`,
        `Minimum 3 lata doświadczenia na samodzielnym stanowisku kierowniczym w Dziale Utrzymania Ruchu w branży produkcyjnej (znajomość branży tworzyw sztucznych, obróbki aluminium lub szkła będzie dodatkowym atutem)`,
        `Wiedzę techniczną z zakresu eksploatacji maszyn i urządzeń przemysłowych`,
        `Umiejętność organizacji pracy własnej i podległego zespołu`,
        `Umiejętności wielopłaszczyznowego rozwiązywania problemów`,
        `Umiejętność samodzielnego podejmowania decyzji, poczucie odpowiedzialności`,
        `Doświadczenie w usprawnianiu i automatyzacji procesów produkcyjnych`,
      ],
      paragraphs: [
        `Firma oferuje pracę na podstawie umowy o pracę lub umowy B2B w atmosferze współpracy. Dodatkowo otrzymasz:`,
      ],
    },
    effects: [
      `Bardzo atrakcyjne wynagrodzenie`,
      `Niezbędne narzędzia pracy`,
      `Dofinansowanie wypoczynku`,
      `Możliwość zaciągnięcia kredytu na preferencyjnych warunkach`,
      `Możliwość otrzymania dofinansowania do studiów lub wybranych szkoleń`,
      `Możliwość doskonalenia umiejętności podczas szkoleń`,
      `Paczki lub bony świąteczne`,
      `W biurze zawsze dostępne są darmowe kawa i napoje`,
    ],
  },
  {
    type: `Głuchołazy woj. Opolskie`,
    title: `Dyrektor sprzedaży ds. eksportu i sprzedaży krajowej.`,
    description: [
      `Nasz Klient to dynamicznie rozwijający się i działający od ponad 25 lat holding firm z ugruntowaną i stabilną pozycją rynkową, prowadzący operacje w różnych branżach na rynku krajowym i na wielu rynkach zagranicznych.`,
    ],
    target: [
      `W związku z planowaną dalszą ekspansją na rynki zagraniczne oraz wzmocnieniem pozycji na rynku krajowym do jednej ze swoich spółek działających w branży elektro poszukuje doświadczonego managera z pasją, osoby przedsiębiorczej i samodzielnej, która wytyczy ścieżkę wzrostu i wdroży swój plan w życie. Jeśli sprzedaż jest tym co dodaje Ci energii do działania, jesteś twórczy, lubisz pracę z ludźmi a myślenie strategiczne i operacyjne to Twoje atuty, wyślij swoją aplikację.`,
      `Twój nowy pracodawca oferuje Ci atrakcyjne wynagrodzenie, autonomię i samodzielność w działaniu abyś mógł osiągać swoje cele i czuć się dobrze w nowym miejscu pracy. Dodatkowo otrzymasz możliwość pracy hybrydowej abyś mógł zaoszczędzić swój prywatny czas, a to tylko niektóre możliwości jakie znajdziesz w ofercie.`,
      `Osoba zatrudniona na tym stanowisku będzie podlegać bezpośrednio pod Prezesa firmy oraz raportować efekty swoich działań do zarządu przedsiębiorstwa.`,

      `Jeśli to brzmi dla Ciebie interesująco wyślij swoje CV na adres rekrutacje@daimly.pl,
W tytule maila wpisz numer oferty: 15/03/2022`,
    ],
    receivers: [
      `Zapewnienie wzrostu sprzedaży eksportowej `,
      `Budowanie i wdrażanie przyjętej do realizacji strategii sprzedażowej, oraz odpowiedzialność za jej realizację  `,
      `Planowanie budżetu i realizacja jego założeń – odpowiedzialność z P&L`,
      `Rozwój sprzedaży eksportowej poprzez zawieranie i negocjowanie umów i nadzór nad ich realizacją`,
      `Zarządzanie pracą działu sprzedaży`,
      `Ocena istniejących i proponowanie nowych możliwości biznesowych`,
      `Zbudowanie systemu monitoringu oraz analiza danych rynkowych danego segmentu oraz otoczenia konkurencyjnego`,
      `Raportowanie wyników pracy swojej i podległego zespołu`,
    ],
    structure: {
      description: `Aby skutecznie i efektywnie wykonywać swoje obowiązki, oraz osiągać cele jakie organizacja postawi wybranemu Kandydatowi/ Kandydatce  idealna osoba powinna spełniać następujące kryteria i powinna posiadać:`,
      modules: [
        `Co najmniej 5 letnie poparte sukcesami doświadczenie w zarządzaniu sprzedażą i zespołami sprzedażowymi`,
        `Doświadczenie w budowaniu sprzedaży eksportowej`,
        `Znajomość języka angielskiego na poziomie umożliwiającym swobodną biznesową komunikację w mowie i piśmie – warunek niezbędny`,
        `Łatwość w rozumieniu technicznych zagadnień (doświadczenie w branży elektro lub pokrewnej może  być dodatkowym atutem)`,
        `Znajomość technik sprzedaży oraz doświadczenie w prowadzeniu negocjacji`,
        `Wysoki poziom kompetencji komunikacyjnych`,
        `Elastyczność w działaniu, umiejętność przystosowywania się do zmieniających się warunków`,
        `Przedsiębiorczość - umiejętność dostrzegania szans i ich wykorzystywania`,
        `Ambicje dające motywację do wygrywania z najlepszymi i dążenie do wprowadzania ulepszeń`,
        `Umiejętność strategicznego myślenia`,
        `Wykształcenie wyższe`,
        `Znajomości obsługi komputera (pakiet Office) oraz prawo jazdy kat. B`,
        `Znajomość i chęć korzystania z systemów ERP będzie dodatkowym atutem ( preferowany SAP BUSSINESS ONE)`,
      ],
      paragraphs: [
        `Firma oferuje pracę na podstawie umowy o pracę lub umowy B2B w atmosferze współpracy. Dodatkowo otrzymasz:`,
      ],
    },
    effects: [
      `Bardzo atrakcyjne wynagrodzenie`,
      `Niezbędne narzędzia pracy w tym samochód z segmentu D`,
      `Samochód do użytku prywatnego`,
      `Praca w trybie hybrydowym (biuro, praca w domu, oraz delegacje)`,
      `Dofinansowanie wypoczynku`,
      `Możliwość zaciągnięcia kredytu na preferencyjnych warunkach`,
      `Możliwość otrzymania dofinansowania do studiów lub wybranych szkoleń`,
      `Możliwość doskonalenia umiejętności podczas szkoleń`,
      `Paczki lub bony świąteczne`,
      `W biurze zawsze dostępne są darmowe kawa i napoje`,
    ],
  },
  {
    type: `Wrocław i okolice`,
    title: `Customer Success Manager_Sales Manager.`,
    description: [
      `Nasz Klient to dynamicznie rozwijająca się i innowacyjna firma z branży budowlanej, posiadająca klientów  na kilku kontynentach, w której panuje nietuzinkowa atmosfera współpracy, możliwości rozwoju zawodowego i awansu. Obecnie poszukujemy osoby, która chce realizować swoją karierę zawodową w sprzedaży w kanale B2B.`,
    ],
    target: [
      `W swojej codziennej pracy będziesz się czuł jak ryba w wodzie, jeśli lubisz współpracę z ludźmi, a atrakcyjne wynagrodzenie i dodatkowe premie motywują Cię do działania.  Innowacyjne systemy i procesy sprzedażowe stosowane w firmie sprawiają, iż w opisanej poniżej roli, będziesz się skupiać na otrzymanych klientach i nie będziesz ich samodzielnie poszukiwać. Elastyczne godziny oraz możliwość pracy zdalnej abyś mógł zaoszczędzić swój prywatny czas, to tylko niektóre możliwości jakie znajdziesz w ofercie.`,
      `Jeśli to brzmi dla Ciebie interesująco wyślij swoje CV na adres rekrutacje@daimly.pl,
W tytule maila wpisz numer oferty: 10/02/2022`,
      `Daimley jest specjalistyczną butikową agencją rekrutacyjną wpisaną do KRAZ pod numerem 26296. `,
    ],
    receivers: [
      `Zarządzanie i współpraca z istniejącymi oraz nowymi klientami firmy w taki sposób, aby odczuwali zadowolenie i satysfakcję ze współpracy`,
      `Koordynacja  współpracy pomiędzy wszystkimi uczestnikami procesu - koordynacja projektów i zarządzanie łańcuchem od zakupu do sprzedaży `,
      `Analiza rozwiązań i optymalizacja zamówień pod kątem potrzeb klienta`,
      `Zarządzanie i ciągły nadzór nad procesem obsługi klienta`,
      `Przygotowywanie ofert oraz zarządzanie procesem ich realizacji`,
      `Negocjowanie warunków współpracy z dostawcami i klientami `,
      `Finalizacja transakcji `,
      `Systematyczna rejestrowanie procesów w systemie CRM`,
    ],
    structure: {
      description: `Aby odnosić sukcesy na oferowanym stanowisku oczekujemy od Ciebie:`,
      modules: [
        `Nastawienia na klienta, ze szczególnym zwróceniem uwagi na dbałość o jakość obsługi na najwyższym poziomie, umiejętność rozwiązywania problemów i pro aktywność`,
        `Przedsiębiorczości, rozumianej jako umiejętność zrozumienie potrzeb klienta i doradzenia optymalnego rozwiązania oraz umiejętność wykorzystywania okazji biznesowych do rozwoju współpracy `,
        `Uzdolnień technicznych demonstrowanych jako posiadanie zmysłu technicznego pozwalającego na łatwe przyswajanie technicznej wiedzy i informacji o produktach a następnie rozmowę o technicznych aspektach projektu`,
        `Zrozumienia i chęci korzystania z możliwości, jakie daje marketing/sales automation – jeśli korzystałaś/eś z jakiegoś rozwiązania z tej dziedziny i widzisz korzyści z tego płynące będzie to dodatkowy atut`,
        `Chęci rozwijania się i awansu   `,
        `Nastawienia na osiągnięcie celu `,
        `Asertywności i energii w działaniu`,
        `Komunikatywności i ukierunkowania na współpracę`,
        `Komunikatywnej znajomość języka angielskiego w mowie i piśmie`,
        `Dodatkowym atutem będzie znajomość systemów CRM oraz umiejętność przygotowywania ofert.`,
        `Doświadczenie w branży budowlanej nie jest konieczne, ale będzie dodatkowym atutem`,
      ],
      paragraphs: [
        `Firma oferuje pracę na podstawie umowy o pracę lub umowy B2B w nowoczesnym i międzynarodowym środowisku o unikalnej atmosferze. Dodatkowo otrzymasz:`,
      ],
    },
    effects: [
      `Elastyczne godziny pracy – bo  rozumiemy potrzeby młodych mam i ojców .`,
      `Niezbędne narzędzia pracy.`,
      `Możliwość pracy w trybie hybrydowym (po 3 miesiącach pracy).`,
      `Bardzo atrakcyjne wynagrodzenie składające się z wysokiej podstawy i premii.`,
      `Tygodniowe szkolenie wstępne i wdrożenie,  aby zapewnić Ci komfort i przyjemność z pracy w nowym miejscu.`,
      `Dofinansowywanie Twojego rozwoju w wybranych przez Ciebie miejscach - np.: dofinansowanie studiów lub kursów rozwijających Twoje kompetencje i umiejętności.`,
      `Szkolenia przygotowywane przez ekspertów oraz prowadzone przez specjalistyczne firmy zewnętrzne, aby ułatwić i dać Ci możliwość rozwoju kariery zawodowej i awansu .`,
      `Możliwość uczestnictwa w wyjazdach integracyjnych.`,
      `a w  biurze zawsze kawę, herbatę i wodę`,
    ],
  },
  {
    type: `Wrocław i okolice`,
    title: `Junior Sales Manager.`,
    description: [
      `Nasz Klient to dynamicznie rozwijająca się i innowacyjna firma z branży budowlanej, posiadająca klientów na kilku kontynentach, w której panuje nietuzinkowa atmosfera współpracy, możliwości rozwoju zawodowego i awansu. Obecnie poszukujemy osób, które rozpoczynają swoją karierę i chcą realizować się w dziale sprzedaży w środowisku B2B.`,
    ],
    target: [
      `W swojej codziennej pracy będziesz się czuł jak ryba w wodzie, jeśli lubisz współpracę z ludźmi, lubisz i chcesz się uczyć aby poprawiać swoje kwalifikacje. Szukamy osób ambitnych i dążących do osiągnięcia celu. W swojej pracy będziesz obsługiwać istniejących klientów firmy oraz wspierać innych członków zespołu w realizacji ich zadań. W zamian otrzymasz pakiet szkoleń, atrakcyjne wynagrodzenie składające się z części stałej i premiowej.`,
      `Twój nowy pracodawca oferuje Ci wsparcie abyś mógł osiągać swoje cele i czuć się dobrze w nowym miejscu pracy. Dodatkowo otrzymasz możliwość pracy zdalnej abyś mógł zaoszczędzić swój prywatny czas, a to tylko niektóre możliwości jakie znajdziesz w ofercie.`,

      `Jeśli to brzmi dla Ciebie interesująco wyślij swoje CV na adres rekrutacje@daimley.pl
W tytule maila wpisz numer oferty: 14/02/2022`,
      `Daimley jest specjalistyczną butikową agencją rekrutacyjną wpisaną do KRAZ pod numerem 26296. `,
    ],
    receivers: [
      `Obsługa zamówień i realizacja celów sprzedażowych`,
      `Współpracy pomiędzy wszystkimi uczestnikami procesu `,
      `Analiza rozwiązań i optymalizacja zamówień pod kątem potrzeb klienta`,
      `Zarządzanie i ciągły nadzór nad procesem obsługi klienta`,
      `Przygotowywanie ofert oraz zarządzanie procesem ich realizacji`,
      `Negocjowanie warunków współpracy z dostawcami i klientami `,
      `Finalizacja transakcji `,
      `Systematyczna rejestrowanie procesów w systemie CRM`,
    ],
    structure: {
      description: `Dasz sobie radę jeśli posiadasz:`,
      modules: [
        `Komunikatywną znajomość języka angielskiego w mowie i piśmie - w kontaktach z klientami będziesz posługiwać się wyłącznie w języku angielskim`,
        `Chęć pogłębiania swojej wiedzy i umiejętności `,
        `Nastawienie na potrzeby klienta`,
        `Uzdolnienia techniczne, czyli posiadanie tzw. zmysłu technicznego pozwalającego na łatwe przyswajanie technicznej wiedzy i informacji o produktach, a następnie umiejętność wykorzystania tej wiedzy w rozmowie o technicznych aspektach projektu`,
        `Chęci rozwijania się i awansu   `,
        `Nastawienia na osiągnięcie celu `,
        `Energię w działaniu`,
        `Komunikatywności i ukierunkowania na współpracę`,
      ],
      paragraphs: [
        `Firma oferuje pracę na podstawie umowy o pracę lub umowy B2B w nowoczesnym i międzynarodowym środowisku o unikalnej atmosferze. Dodatkowo otrzymasz:`,
      ],
    },
    effects: [
      `Elastyczne godziny pracy – bo  rozumiemy potrzeby młodych mam i ojców .`,
      `Niezbędne narzędzia pracy.`,
      `Możliwość pracy w trybie hybrydowym (po 3 miesiącach pracy).`,
      `Bardzo atrakcyjne wynagrodzenie składające się z wysokiej podstawy i premii.`,
      `Tygodniowe szkolenie wstępne i wdrożenie,  aby zapewnić Ci komfort i przyjemność z pracy w nowym miejscu.`,
      `Dofinansowywanie Twojego rozwoju w wybranych przez Ciebie miejscach - np.: dofinansowanie studiów lub kursów rozwijających Twoje kompetencje i umiejętności.`,
      `Szkolenia przygotowywane przez ekspertów oraz prowadzone przez specjalistyczne firmy zewnętrzne, aby ułatwić i dać Ci możliwość rozwoju kariery zawodowej i awansu .`,
      `Możliwość uczestnictwa w wyjazdach integracyjnych.`,
      `a w  biurze zawsze kawę, herbatę i wodę`,
    ],
  },
];
